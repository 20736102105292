export enum NoteStatus {
  Draft = "draft",
  Published = "published",
  Archived = "archived",
}

export type NoteSummary = {
  id: string;
  title: string;
  summary: string;
  tags: string[];
  status: NoteStatus;
  createdAt: number;
  lastUpdatedAt: number;
};

export type Note = NoteSummary & {
  content: string;
};
