import React from "react";
import styles from "../styles/app.css";

type LoaderProps = {
  size?: 2;
};

const Loader: React.FC<LoaderProps> = ({ size }) => {
  return (
    <div className="text-center my-3">
      <div className={`${styles.loader} ${size === 2 ? styles.loader2x : ""}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
