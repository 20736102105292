import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, storage } from "../utils";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [wait, setWait] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (storage.getAuthToken()) {
      navigate("/");
    }
  }, []);

  const doLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setWait(true);
      await api.login(email, password);
      alert("Login successful!");
      navigate("/");
    } catch (err) {
      alert(err.message);
    }
    setWait(false);
  };

  return (
    <form onSubmit={doLogin} className="mt-5 pt-5">
      <h1>Login</h1>
      <hr />
      <input
        className="form-control"
        type="email"
        placeholder="email"
        maxLength={30}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <br />
      <input
        className="form-control"
        type="password"
        placeholder="password"
        maxLength={30}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <br />
      <button type="submit" className="btn btn-outline-success" disabled={wait}>
        {wait ? "Logging in..." : "Login"}
      </button>
    </form>
  );
};

export default Login;
