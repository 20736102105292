import { useParams } from "react-router-dom";
import { noteUtils } from ".";

export const useNoteIdFromPath = () => {
  const { noteId } = useParams();
  if (!noteId) {
    return undefined;
  }
  return noteUtils.getNoteIdFromPathId(noteId);
};
