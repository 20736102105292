import React, { useState } from "react";
import { Link } from "react-router-dom";
import NoteEditor, { NoteEditProps } from "../components/NoteEditor";
import { api } from "../utils";

const NewNote = () => {
  const [saving, setSaving] = useState(false);

  const onCreateNote = async (props: NoteEditProps) => {
    setSaving(true);
    try {
      await api.createNote({ ...props });
      alert("Note saved successfully!");
    } catch (err) {
      alert(err.message);
    }
    setSaving(false);
  };
  return (
    <div className="row">
      <div className="col-6">
        <h1>New Note</h1>
      </div>
      <div className="col-6 pt-2 text-end">
        <Link to="/" className="btn btn-outline-info">
          Home
        </Link>
      </div>
      <hr />
      <div className="col-12">
        <NoteEditor onSave={onCreateNote} saving={saving} />
      </div>
    </div>
  );
};

export default NewNote;
