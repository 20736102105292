import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="row text-center">
      <div className="col">
        <Link to="/">Home</Link> | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link>
      </div>
    </footer>
  );
};

export default Footer;
