import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import { system } from "./utils";
import styles from "./styles/app.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsafeStyle = styles as any;
document.body.classList.add(
  unsafeStyle[system.getDevice()],
  unsafeStyle[system.getOS()],
  unsafeStyle[system.getBrowser()],
);

createRoot(document.getElementById("root")).render(<App />);
