import { storage } from ".";
import { Note, NoteStatus, NoteSummary } from "../types";

type Stage = "prod" | "test"; // test => local | dev

const stage: Stage = (function () {
  switch (window?.location?.hostname) {
    case "notingdev.com":
    case "www.notingdev.com":
      return "prod";
    default:
      return "test";
  }
})();

const apiRoot =
  stage === "prod"
    ? "https://aub2eebeq3.execute-api.us-east-1.amazonaws.com/prod/api"
    : "https://kxp4h3fida.execute-api.us-east-1.amazonaws.com/dev/api";

const v1 = (path: string) => `${apiRoot}/v1${path}`;

type CreateNoteRequest = {
  title: string;
  summary: string;
  content: string;
  tags: string[];
  status: NoteStatus;
};

type UpdateNoteRequest = {
  title: string;
  summary: string;
  content: string;
  tags: string[];
  status: NoteStatus;
};

type ListNotesResponse = {
  nextToken?: string;
  items: NoteSummary[];
};

class Api {
  async login(email: string, password: string) {
    const response = await fetch(v1("/auth/login"), {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    await this.verifyOkResponse(response);

    const res = await response.json();
    storage.setAuthToken(res.token);
  }

  async createNote(request: CreateNoteRequest): Promise<void> {
    const response = await fetch(v1("/notes"), {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        ...this.getAuthHeader(),
      },
    });

    await this.verifyOkResponse(response);
  }

  async updateNote(noteId: string, request: UpdateNoteRequest): Promise<void> {
    const response = await fetch(v1(`/notes/${encodeURIComponent(noteId)}`), {
      method: "PUT",
      body: JSON.stringify(request),
      headers: {
        "Content-Type": "application/json",
        ...this.getAuthHeader(),
      },
    });

    await this.verifyOkResponse(response);
  }

  async getNote(noteId: string): Promise<Note> {
    const response = await fetch(v1(`/notes/${encodeURIComponent(noteId)}`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...this.getAuthHeader(true),
      },
    });

    await this.verifyOkResponse(response);

    const res = await response.json();
    return res.note;
  }

  async deleteNote(noteId: string): Promise<void> {
    const response = await fetch(v1(`/notes/${encodeURIComponent(noteId)}`), {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...this.getAuthHeader(),
      },
    });

    await this.verifyOkResponse(response);
  }

  async listNotes(nextToken: string | undefined = undefined, limit = 10): Promise<ListNotesResponse> {
    const response = await fetch(v1(`/notes?l=${limit}${nextToken ? `&nt=${encodeURIComponent(nextToken)}` : ""}`), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...this.getAuthHeader(true),
      },
    });

    await this.verifyOkResponse(response);

    const res = await response.json();
    return res as ListNotesResponse;
  }

  private async verifyOkResponse(response: Response) {
    if (!response.ok) {
      let res;
      try {
        res = await response.json();
      } catch (err) {
        console.error(err);
        throw new Error(`${response.status}`);
      }
      throw new Error(res.error);
    }
  }

  private getAuthHeader(optional = false) {
    const authToken = storage.getAuthToken();
    if (!authToken) {
      if (optional) {
        return {};
      }
      throw new Error("Login first to perform this action!");
    }
    return { Authorization: `Bearer ${authToken}` };
  }
}

export default new Api();
