import React from "react";
import Layout from "../components/Layout";

const About = () => {
  return (
    <Layout>
      <h1>About</h1>
      <hr />
      <p>Hi!</p>
      <p>
        I'm a software developer. I use this website to take some notes about the things I google in my day to day work.
      </p>
      <p>That's all about it; just a developer taking some notes.</p>
    </Layout>
  );
};

export default About;
