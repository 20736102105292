import React, { useEffect, useState } from "react";
import Note from "../components/Note";
import NoteSearch from "../components/NoteSearch";
import NoteSummary from "../components/NoteSummary";
import Loader from "../components/Loader";
import styles from "../styles/app.css";
import { api, hooks } from "../utils";
import { Note as NoteModel, NoteSummary as NoteSummaryModel } from "../types";
import Layout from "../components/Layout";

const Home = () => {
  const noteId = hooks.useNoteIdFromPath();
  const [notes, setNotes] = useState<NoteSummaryModel[]>([]);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      let nextToken: string | undefined = undefined;

      setLoading(true);

      do {
        try {
          const response = await api.listNotes(nextToken);
          setNotes((current) => [...current, ...response.items]);
          nextToken = response.nextToken;
        } catch (err) {
          alert(err.message);
        }
      } while (nextToken);

      setLoading(false);
    }

    load();
  }, []);

  const filterNotes = (note: NoteModel) =>
    note.title.toLowerCase().indexOf(keyword) >= 0 ||
    note.summary.toLowerCase().indexOf(keyword) >= 0 ||
    note.tags.some((tag) => tag.startsWith(keyword));

  const noteIdToDisplay = noteId || notes[0]?.id;

  return (
    <Layout>
      <div className="row">
        <div className={`${noteId ? "d-none d-md-block" : "col-12"} col-md-4 col-lg-3 px-0`}>
          <div className={styles.noteSearch}>
            <NoteSearch initialValue={keyword} onChange={(key) => setKeyword(key.toLowerCase())} />
          </div>
          <div className={styles.noteList}>
            {notes.length > 0 &&
              notes
                .filter(filterNotes)
                .map((note) => <NoteSummary key={note.id} summary={note} selected={note.id === noteId} />)}
            {!loading && notes.length === 0 && <div className="alert alert-warning">No notes found!</div>}
            {loading && <Loader />}
          </div>
        </div>
        <div className={`${noteId ? "d-block" : "d-none d-md-block"} col-md-8 col-lg-9 ${styles.homeRightPane}`}>
          {loading && !noteIdToDisplay && <Loader size={2} />}
          {noteIdToDisplay && <Note noteId={noteIdToDisplay} />}
          {!noteIdToDisplay && !loading && notes.length === 0 && (
            <div className="alert alert-warning">No note selected!</div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Home;
