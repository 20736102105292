import React, { useEffect, useState } from "react";
import { Note as NoteModel } from "../types";
import NoteMeta from "./NoteMeta";
import styles from "../styles/app.css";
import { api } from "../utils";
import Loader from "./Loader";
import NoteControls from "./NoteControls";
import NoteContent from "./NoteContent";

export type NoteProps = {
  noteId: string;
};

enum LoadState {
  Loading,
  Success,
  Error,
}

const Note: React.FC<NoteProps> = ({ noteId }) => {
  const [loadState, setLoadState] = useState(LoadState.Loading);
  const [error, setError] = useState("");
  const [note, setNote] = useState<NoteModel>(undefined);

  useEffect(() => {
    (async function () {
      setLoadState(LoadState.Loading);
      setError("");

      try {
        const note = await api.getNote(noteId);
        setNote(note);
        setLoadState(LoadState.Success);
        document.title = "NotingDev :: " + note.title;
      } catch (err) {
        setLoadState(LoadState.Error);
        setError(err.message);
      }
    })();
  }, [noteId, setNote, setError, setLoadState]);

  if (loadState === LoadState.Error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  if (loadState === LoadState.Loading) {
    return <Loader size={2} />;
  }

  return (
    <div className={styles.note}>
      <h1>{note.title}</h1>
      <NoteMeta summary={note} />
      <NoteControls note={note} />
      <p className={`ps-3 text-muted mt-3 ${styles.noteSummary}`}>{note.summary}</p>
      <NoteContent markdown={note.content} />
    </div>
  );
};

export default Note;
