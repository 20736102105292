import { NoteSummary } from "../types";

const allowedChars = new Set("abcdefghijklmnopqrstuvwxyz1234567890-".split(""));

export const generatePathId = (note: NoteSummary) => {
  let pathId = "";
  const chars = note.title.toLowerCase().split("");
  for (const c of chars) {
    if (allowedChars.has(c)) {
      pathId += c;
    }
    if (c === " ") {
      pathId += "-";
    }
  }
  while (pathId.endsWith("-")) {
    pathId = pathId.substring(0, pathId.length - 1);
  }
  return `${pathId}-${note.id}`;
};

export const getNoteIdFromPathId = (pathId: string) => {
  return pathId.substring(pathId.lastIndexOf("-") + 1);
};
