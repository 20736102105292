import React, { FormEvent, useState } from "react";
import { marked } from "marked";
import { NoteStatus } from "../types";
import styles from "../styles/app.css";
import NoteContent from "./NoteContent";

marked.setOptions({
  gfm: true,
});

export type NoteEditProps = {
  title: string;
  summary: string;
  content: string;
  tags: string[];
  status: NoteStatus;
};

export type NoteEditorProps = Partial<NoteEditProps> & {
  saving: boolean;
  onSave: (props: NoteEditProps) => void;
};

enum PreviewMode {
  None,
  Split,
  FullPreview,
}

const validate = (note: NoteEditProps): string[] => {
  const errors: string[] = [];
  if (note.title.length < 10 || note.title.length > 80) {
    errors.push("title must be 10 - 80 characters");
  }
  if (note.summary.length < 50 || note.summary.length > 250) {
    errors.push("summary must be 50 - 250 characters");
  }
  if (note.content.length < 200 || note.content.length > 12000) {
    errors.push("content must be 200 - 12000 characters");
  }
  if (note.tags.length < 1 || note.tags.length > 5) {
    errors.push("there must be 1 - 5 tags");
  }
  return errors;
};

const toTagArray = (tagStr: string) =>
  tagStr
    .split(",")
    .map((t) => t.trim())
    .filter((t) => t.length > 0);

const NoteEditor: React.FC<NoteEditorProps> = (props) => {
  const [title, setTitle] = useState(props.title || "");
  const [summary, setSummary] = useState(props.summary || "");
  const [content, setContent] = useState(props.content || "");
  const [tags, setTags] = useState(props.tags?.join(", ") || "");
  const [status, setStatus] = useState(props.status || NoteStatus.Draft);
  const [previewMode, setPreviewMode] = useState(PreviewMode.Split);

  const onSave = (e: FormEvent) => {
    e.preventDefault();
    const note = {
      title,
      summary,
      content,
      status,
      tags: toTagArray(tags),
    };
    const errors = validate(note);
    if (errors.length > 0) {
      alert(errors.join("\n"));
    } else {
      props.onSave(note);
    }
  };

  return (
    <form onSubmit={onSave}>
      <div className="mb-3">
        <label htmlFor="title" className="form-label">
          Title (10 - 80 -&gt; {title.length})
        </label>
        <input
          className="form-control"
          placeholder="title"
          id="title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="summary" className="form-label">
          Summary (50 - 250 -&gt; {summary.length})
        </label>
        <textarea
          className="form-control"
          placeholder="summary"
          id="summary"
          onChange={(e) => setSummary(e.target.value)}
          value={summary}
        ></textarea>
      </div>
      <div className="mb-3">
        <label htmlFor="content" className="form-label">
          Content (200 - 12000 -&gt; {content.length})
        </label>
        <div className={`row ${styles.noteEditor}`}>
          <div
            className={
              previewMode === PreviewMode.None ? "col-12" : previewMode === PreviewMode.Split ? "col-6" : "d-none"
            }
          >
            <textarea
              className="form-control"
              placeholder="content"
              id="content"
              onChange={(e) => setContent(e.target.value)}
              value={content}
            ></textarea>
          </div>
          <div
            className={`${styles.notePreview} ${
              previewMode === PreviewMode.None ? "d-none" : previewMode === PreviewMode.Split ? "col-6" : "col-12"
            }`}
          >
            {content ? <NoteContent markdown={content} /> : <span className="text-muted">Note preview</span>}
          </div>
          <div className="col-12">
            <div className="btn-group my-2" role="group" aria-label="Preview mode">
              <button
                type="button"
                onClick={() => setPreviewMode(PreviewMode.None)}
                className={`btn btn-outline-info ${previewMode === PreviewMode.None && "active"}`}
              >
                Editor only
              </button>
              <button
                type="button"
                onClick={() => setPreviewMode(PreviewMode.Split)}
                className={`btn btn-outline-info ${previewMode === PreviewMode.Split && "active"}`}
              >
                Split
              </button>
              <button
                type="button"
                onClick={() => setPreviewMode(PreviewMode.FullPreview)}
                className={`btn btn-outline-info ${previewMode === PreviewMode.FullPreview && "active"}`}
              >
                Preview only
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="tags" className="form-label">
          Tags (1 - 5 -&gt; {toTagArray(tags).length})
        </label>
        <input
          className="form-control"
          placeholder="tags: tag1, tag2"
          id="tags"
          onChange={(e) => setTags(e.target.value)}
          value={tags}
        ></input>
      </div>
      <div className="mb-3">
        <label htmlFor="status" className="form-label">
          Status
        </label>
        <select
          className="form-control"
          placeholder="status"
          id="status"
          onChange={(e) => setStatus(e.target.value as NoteStatus)}
          value={status}
        >
          <option value={NoteStatus.Draft}>Draft</option>
          <option value={NoteStatus.Published}>Published</option>
          <option value={NoteStatus.Archived}>Archived</option>
        </select>
      </div>
      <button type="submit" className="btn btn-outline-success" disabled={props.saving}>
        {props.saving ? "Saving..." : "Save"}
      </button>
    </form>
  );
};

export default NoteEditor;
