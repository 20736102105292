import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { hooks, storage } from "../utils";
import styles from "../styles/app.css";

const NavBar = () => {
  const isAdmin = storage.getAuthToken();
  const noteId = hooks.useNoteIdFromPath();
  const navigate = useNavigate();

  const onLogout = () => {
    storage.removeAuthToken();
    navigate("/");
  };

  return (
    <div className={`row ${styles.navBar}`}>
      <div className="col d-md-none">
        {noteId && (
          <Link className="btn btn-sm btn-outline-info" to="/">
            ← Home
          </Link>
        )}
      </div>
      <div className="col text-md-start text-end">
        <h3>
          <Link className={styles.navBarTitle} to="/">
            notingdev.com
          </Link>
        </h3>
      </div>
      {isAdmin && (
        <div className="col text-end">
          <button type="button" className="btn btn-outline-warning me-2" onClick={onLogout}>
            Logout
          </button>
          <Link to={"/new"} className="btn btn-outline-success">
            New Note
          </Link>
        </div>
      )}
    </div>
  );
};

export default NavBar;
