type StorageEntry<T> = {
  value: T;
  expires?: number;
};
const remove = (key: string) => {
  window.localStorage.removeItem(key);
};

const set = <T>(key: string, value: T, expires?: number) => {
  window.localStorage.setItem(key, JSON.stringify({ value, expires }));
};

const get = <T>(key: string): T | undefined => {
  const json = window.localStorage.getItem(key);
  if (!json) {
    return undefined;
  }
  const entry = JSON.parse(json) as StorageEntry<T>;
  if (entry.expires > 0 && entry.expires < Date.now()) {
    window.localStorage.removeItem(key);
    return undefined;
  }
  return entry.value;
};

const KEY_AUTH_TOKEN = "auth-token";
const AUTH_TOKEN_LIFETIME = 1000 * 60 * 60 * 24 * 30; // 30 days;

export const removeAuthToken = () => remove(KEY_AUTH_TOKEN);
export const getAuthToken = () => get<string>(KEY_AUTH_TOKEN);
export const setAuthToken = (token: string) => set(KEY_AUTH_TOKEN, token, Date.now() + AUTH_TOKEN_LIFETIME);
