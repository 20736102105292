import hljs from "highlight.js";
import { marked } from "marked";
import React, { useEffect } from "react";
import styles from "../styles/app.css";

type NoteContentProps = {
  markdown: string;
};

const NoteContent: React.FC<NoteContentProps> = ({ markdown }) => {
  useEffect(() => {
    setTimeout(hljs.highlightAll, 100);
    document.querySelectorAll("[data-markdown] table").forEach((t) => t.classList.add("table"));
    document.querySelectorAll("[data-markdown] a").forEach((t) => t.setAttribute("target", "_blank"));
  }, [markdown]);

  return (
    <div
      className={styles.noteContent}
      data-markdown
      dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
    ></div>
  );
};

export default NoteContent;
