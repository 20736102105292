import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Note as NoteModel } from "../types";
import { api, storage } from "../utils";

export type NoteControlProps = {
  note: NoteModel;
};

const NoteControls: React.FC<NoteControlProps> = ({ note }) => {
  const isAdmin = storage.getAuthToken();
  const navigate = useNavigate();

  if (!isAdmin) {
    return null;
  }

  const deleteNote = async () => {
    if (!confirm(`Are you sure you want to delete the note "${note.title}" permanently!`)) {
      return;
    }
    try {
      await api.deleteNote(note.id);
      alert("Note deleted!");
      navigate("/");
    } catch (err) {
      alert(err.message);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-outline-danger me-2" onClick={deleteNote}>
        Delete
      </button>
      <Link to={`/${note.id}/edit`} className="btn btn-outline-warning">
        Edit
      </Link>
    </>
  );
};

export default NoteControls;
