import React from "react";

export type NoteSearchProps = {
  initialValue: string;
  onChange: (keyword: string) => void;
};

const NoteSearch: React.FC<NoteSearchProps> = ({ onChange }) => {
  return (
    <input
      type="text"
      className="form-control"
      id="search"
      placeholder="Search in title, summary or tag"
      aria-describedby="searchKeyword"
      onChange={(e) => onChange(e.target.value)}
    ></input>
  );
};

export default NoteSearch;
