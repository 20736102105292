import React from "react";
import Layout from "../components/Layout";

const Contact = () => {
  return (
    <Layout>
      <h1>Contact</h1>
      <hr />
      <p>
        If you want to get in touch with me just send an email to{" "}
        <code>
          &lt;location.hostname.split(".").slice(-2, -1)&gt;&lt;et&gt;&lt;google's mail
          service&gt;&lt;dot&gt;&lt;location.hostname.split(".").slice(-1)&gt;
        </code>
      </p>
    </Layout>
  );
};

export default Contact;
