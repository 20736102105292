import React from "react";
import { NoteStatus as NoteStatusType, NoteSummary } from "../types";
import styles from "../styles/app.css";
import { storage } from "../utils";

export type NoteMetaProps = {
  summary: NoteSummary;
};

const StatusBackground = {
  [NoteStatusType.Draft]: "warning",
  [NoteStatusType.Archived]: "danger",
  [NoteStatusType.Published]: "success",
};

const NoteStatus = ({ status }: { status: NoteStatusType }) => {
  const isAdmin = !!storage.getAuthToken();
  if (!isAdmin) {
    return null;
  }
  return <span className={`badge rounded-pill bg-${StatusBackground[status]}`}>{status}</span>;
};

const NoteMeta: React.FC<NoteMetaProps> = ({ summary }) => (
  <small className={`mb-2 ${styles.noteMeta}`}>
    <span>{new Date(summary.lastUpdatedAt).toISOString().substring(0, 10)}</span>{" "}
    <span className="me-2">[{summary.tags.join(", ")}]</span>
    <NoteStatus status={summary.status} />
  </small>
);

export default NoteMeta;
