import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import NoteEditor, { NoteEditProps } from "../components/NoteEditor";
import { Note } from "../types";
import { api, hooks } from "../utils";

const EditNote = () => {
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState<Note>(undefined);
  const noteId = hooks.useNoteIdFromPath();

  useEffect(() => {
    if (!noteId) {
      alert("Invalid URL, no note id!");
      return;
    }

    (async function () {
      try {
        setNote(await api.getNote(noteId));
      } catch (err) {
        alert(err.message);
      }
    })();
  }, [noteId, setNote]);

  const onSaveNote = async (props: NoteEditProps) => {
    setSaving(true);
    try {
      await api.updateNote(noteId, { ...props });
      alert("Note saved successfully!");
    } catch (err) {
      alert(err.message);
    }
    setSaving(false);
  };

  if (!note) {
    return <Loader size={2} />;
  }

  return (
    <div className="row">
      <div className="col-6">
        <h1>Edit Note</h1>
      </div>
      <div className="col-6 pt-2 text-end">
        <Link to="/" className="btn btn-outline-info">
          Home
        </Link>
      </div>
      <hr />
      <div className="col-12">
        <NoteEditor
          onSave={onSaveNote}
          saving={saving}
          title={note.title}
          summary={note.summary}
          content={note.content}
          tags={note.tags}
          status={note.status}
        />
      </div>
    </div>
  );
};

export default EditNote;
