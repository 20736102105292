import React from "react";
import { Link } from "react-router-dom";
import { NoteSummary as NoteSummaryModel } from "../types";
import styles from "../styles/app.css";
import { noteUtils } from "../utils";
import NoteMeta from "./NoteMeta";

export type NoteSummaryProps = {
  selected: boolean;
  summary: NoteSummaryModel;
};

const NoteSummary: React.FC<NoteSummaryProps> = ({ selected, summary }) => {
  return (
    <Link
      to={`/${noteUtils.generatePathId(summary)}`}
      className={`${styles.noteLink} ${selected && styles.noteLinkSelected}`}
    >
      <h4>{summary.title}</h4>
      <NoteMeta summary={summary} />
      {summary.summary}
    </Link>
  );
};

export default NoteSummary;
